<template>
  <m-select-query
    v-model="customer"
    v-bind="$attrs"
    :label="label"
    :query="autocomplete"
    autocomplete="off"
    clearable
  />
</template>

<script>
import { searchGdprCustomers } from 'api/onwardtravel'
import { MSelectQuery } from 'components/'

export default {
  components: { MSelectQuery },
  props: {
    label: String,
    value: Object
  },
  computed: {
    customer: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    clearCurrentSelection () {
      this.$emit('input', null)
    },
    async autocomplete (query) {
      const { data } = await searchGdprCustomers({ query })
      return data.map((customer) => { return { value: customer.phone_number, label: customer.name } })
    }
  }
}
</script>

<style>

</style>
